@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: #082032;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 50px;
  outline: 1px solid slategrey;
}

.rail::-webkit-scrollbar-track,
.likes-view::-webkit-scrollbar-track,
.imageControl::-webkit-scrollbar-track,
.sidePanel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rail::-webkit-scrollbar-thumb,
.likes-view::-webkit-scrollbar-thumb,
.imageControl::-webkit-scrollbar-thumb,
.sidePanel::-webkit-scrollbar-thumb {
  background-color: #ffffffc0;
  border-radius: 50px;
  outline: 1px solid slategrey;
}


.rail::-webkit-scrollbar {
  height: 0.2em;
}

.sidePanel::-webkit-scrollbar {
  width: 0.5em;
}

.imageControl::-webkit-scrollbar {
  width: 0.4em;
}

.likes-view::-webkit-scrollbar {
  width: 1em;
}

.paper {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 10px;
  box-shadow:
    /* The top layer shadow */
    0 -1px 1px rgba(0, 0, 0, 0.15),
    /* The second layer */
    0 -10px 0 -5px #eee,
    /* The second layer shadow */
    0 -10px 1px -4px rgba(0, 0, 0, 0.15),
    /* The third layer */
    0 -20px 0 -10px #eee,
    /* The third layer shadow */
    0 -20px 1px -9px rgba(0, 0, 0, 0.15);
}