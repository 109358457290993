#range {
    align-items: center;
    height: 7px;
    -webkit-appearance: none;
    background: #8e9ba4;
    opacity: 0.5;
    outline: none;
    border-radius: 15px;
}

#range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #082032;
    cursor: pointer;
    border: 4px solid rgb(159, 176, 187);
    /* box-shadow: -407px 0 0 400px #00fd0a; */
}

option {
    padding: 0px;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity));
    writing-mode: vertical-lr;
    display: flex;
    flex-direction: row;
    border-radius: 50%;
}